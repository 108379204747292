import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { TransactionService } from 'src/app/services/transaction.service';
import Transaction from 'src/app/models/transaction';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent implements OnInit, OnChanges {

  @Input() transaction: Transaction;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  currentTransaction: Transaction = null;
  message = '';

  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.message = '';
  }

  ngOnChanges(): void {
    this.message = '';
    this.currentTransaction = { ...this.transaction };
  }

  /**
  updatePublished(status): void {
    this.transactionService.update(this.currentTransaction.id, { published: status })
      .then(() => {
        this.currentTransaction.published = status;
        this.message = 'The status was updated successfully!';
      })
      .catch(err => console.log(err));
  }**/

  updateTransaction(): void {
    const data = {
      note: this.currentTransaction.note,
      crypto_address: this.currentTransaction.crypto_address,
      phone_number: this.currentTransaction.phone_number,
      amount_fiat_entered: this.currentTransaction.amount_fiat_entered,
      crypto: this.currentTransaction.crypto,
      status: this.currentTransaction.status,
      identity_id: this.currentTransaction.identity_id
    };

    this.transactionService.update(this.currentTransaction.id, data)
      .then(() => this.message = 'The transaction was updated successfully!')
      .catch(err => console.log(err));
  }

  deleteTransaction(): void {
    this.transactionService.delete(this.currentTransaction.id)
      .then(() => {
        this.refreshList.emit();
        this.message = 'The transaction was updated successfully!';
      })
      .catch(err => console.log(err));
  }
}