import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TransactionService } from 'src/app/services/transaction.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnDestroy, OnInit {
  transactions: any;
  currentTransaction = null;
  currentIndex = -1;
  title = '';


  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  min: number;
  max: number;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      const col = Number(data[2].replace(/[^0-9.-]+/g, "")) // filtering by amount
      if ((isNaN(this.min) && isNaN(this.max)) ||
        (isNaN(this.min) && col <= this.max) ||
        (this.min <= col && isNaN(this.max)) ||
        (this.min <= col && col <= this.max)) {
        return true;
      }
      return false;
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true

    };
    this.retrieveTransactions();
  }

  refreshList(): void {
    this.currentTransaction = null;
    this.currentIndex = -1;
    this.retrieveTransactions();
  }


  retrieveTransactions(): void { //tentative solution from https://stackoverflow.com/questions/58124184/angular-firebase-persistence-fetch-new-data-from-firestore

    this.transactionService.getAll().get().pipe(
      map(changes =>
        changes.docs.map(c => ({ id: c.id, ...c.data() })
        )
      )
    ).subscribe(data => {
      this.transactions = data;
      this.dtTrigger.next();
    });
  }

  setActiveTransaction(transaction, index): void {
    this.currentTransaction = transaction;
    this.currentIndex = index;
  }

  toggleAccordian(event, index) { //toggle dropdown -Miguel
    var element = event.target;
    element.classList.toggle("active");
    if (this.transactions[index].isActive) {
      this.transactions[index].isActive = false;
    } else {
      this.transactions[index].isActive = true;
    }
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
  
  ngOnDestroy(): void {
    $.fn['dataTable'].ext.search.pop(); //Min Max search
    this.dtTrigger.unsubscribe(); //table trigger
  }
  filterByCol(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  noteLink(transaction){
    var link = "https://www.blockchain.com/"+ transaction.crypto.toLowerCase() + "/address/" + transaction.crypto_address
    window.open(link);
  }

  /*getAddressRisk(address){
    let http = this.httphandledawg
    const headers = new HttpHeaders()
            .set("X-CustomHeader", "Authorization:ctv1:username:secretkey");
    let data = http.get(("https://rest.ciphertrace.com/api/v1/wallet?address="+address),{headers});
    console.log(data); 
  }*/
}
