export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCC1Xvw2VUJ4hOamdEUptFvOU1IR8L6NKU",
    authDomain: "fgcomplianceportal.firebaseapp.com",
    databaseURL: "https://fgcomplianceportal.firebaseio.com",
    projectId: "fgcomplianceportal",
    storageBucket: "fgcomplianceportal.appspot.com",
    messagingSenderId: "524321802525",
    appId: "1:524321802525:web:190976ff59d08dbea779f4",
    measurementId: "G-MYQRKD2MHV"
  }
};