<div *ngIf="currentIdentity" class="edit-form">
  <h4>Identity</h4>
  <form>
    <div class="form-group">
      <label for="notes">Notes</label>
      <input
        type="text"
        class="form-control"
        id="notes"
        [(ngModel)]="currentIdentity.notes"
        name="notes"
      />
    </div>
    <div class="form-group">
      <label for="first_name">first_name</label>
      <input
        type="text"
        class="form-control"
        id="first_name"
        [(ngModel)]="currentIdentity.first_name"
        name="first_name"
      />
    </div>
    <div class="form-group">
      <label for="last_name">last_name</label>
      <input
        type="text"
        class="form-control"
        id="last_name"
        [(ngModel)]="currentIdentity.last_name"
        name="last_name"
      />
    </div>
    <div class="form-group">
      <label for="phone_numbers">phone_numbers</label>
      <input
        type="text"
        class="form-control"
        id="phone_numbers"
        [(ngModel)]="currentIdentity.phone_numbers"
        name="phone_numbers"
      />
    </div>
    <div class="form-group">
      <label for="btc_addresses">btc_addresses</label>
      <input
        type="text"
        class="form-control"
        id="btc_addresses"
        [(ngModel)]="currentIdentity.btc_addresses"
        name="btc_addresses"
      />
    </div>
    <div class="form-group">
      <label for="licenses">licenses</label>
      <input
        type="text"
        class="form-control"
        id="licenses"
        [(ngModel)]="currentIdentity.licenses"
        name="licenses"
      />
    </div>
    <div class="form-group">
      <label for="identity_id">identity_id</label>
      <input
        type="text"
        class="form-control"
        id="identity_id"
        [(ngModel)]="currentIdentity.identity_id"
        name="identity_id"
      />
    </div>
    <!--<div class="form-group">
      <label for="crypto">crypto</label>
      <input
        type="text"
        class="form-control"
        id="crypto"
        [(ngModel)]="currentIdentity.crypto"
        name="crypto"
      />
    </div>
    
    <div class="form-group">
      <label for="crypto_amount">crypto_amount</label>
      <input
        type="text"
        class="form-control"
        id="crypto_amount"
        [(ngModel)]="currentIdentity.crypto_amount"
        name="crypto_amount"
      />
    </div>-->
    <!--<div class="form-group">
      <label for="fee_at_purchase">fee_at_purchase</label>
      <input
        type="text"
        class="form-control"
        id="fee_at_purchase"
        [(ngModel)]="currentIdentity.fee_at_purchase"
        name="fee_at_purchase"
      />
    </div>
    <div class="form-group">
      <label for="fiat">fiat</label>
      <input
        type="text"
        class="form-control"
        id="fiat"
        [(ngModel)]="currentIdentity.fiat"
        name="fiat"
      />
    </div>
    <div class="form-group">
      <label for="time">time</label>
      <input
        type="text"
        class="form-control"
        id="time"
        [(ngModel)]="currentIdentity.time"
        name="time"
      />
    </div>
    <div class="form-group">
      <label for="machine_id">machine_id</label>
      <input
        type="text"
        class="form-control"
        id="machine_id"
        [(ngModel)]="currentIdentity.machine_id"
        name="machine_id"
      />
    </div>
    <div class="form-group">
      <label for="identity_id">identity_id</label>
      <input
        type="text"
        class="form-control"
        id="identity_id"
        [(ngModel)]="currentIdentity.identity_id"
        name="identity_id"
      />
    </div>-->

    <!--<div class="form-group">
      <label><strong>Status:</strong></label>
      {{ currentIdentity.published ? "Published" : "Pending" }}
    </div>-->
  </form>

  <!--<button
    class="badge badge-primary mr-2"
    *ngIf="currentIdentity.published"
    (click)="updatePublished(false)"
  >
    UnPublish
  </button>
  <button
    *ngIf="!currentIdentity.published"
    class="badge badge-primary mr-2"
    (click)="updatePublished(true)"
  >
    Publish
  </button>

  <button class="badge badge-danger mr-2" (click)="deleteIdentity()">
    Delete
  </button>-->

  <button type="submit" class="badge badge-success" (click)="updateIdentity()">
    Update
  </button>
  <p>{{ message }}</p>
</div>

<div *ngIf="!currentIdentity">
  <br />
  <p>Cannot access this Identity...</p>
</div>
