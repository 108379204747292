<link type="text/css" rel="stylesheet" href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css" />
<nav id="sidebar">
      <div class="sidebar-header">
            <h5>Navigation</h5>
      </div>

      <li class="nav-item">
        <a href="../transactions">Transactions</a>
      </li>
      <li class="nav-item active">
        <a href="../identities">Identities</a>
      </li>
</nav>

<div class="list row">
  <div class="col-md-6">
    <h4>Identity List</h4>
    <ul class="list-group">
      <li
        class="list-group-item"
        *ngFor="let identity of identitys; let i = index"
        [class.active]="i == currentIndex"
        (click)="setActiveIdentity(identity, i)">
        <div class="card" style="width: 18rem;">
          <div *ngIf="identity.selfie">
            <img class="card-img-top" [src]="identity.selfie" alt="Card image cap">
          </div>
          <div class="card-body">
            <h5 class="card-title">{{ identity.name }}</h5>
            <p class="card-text">{{identity.identity_id}}</p>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">{{identity.phone_numbers}}</li>
            <li class="list-group-item">{{identity.btc_addresses}}</li>
            <li class="list-group-item">{{identity.notes}}</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-6">
    <div *ngIf="currentIdentity">
      <app-identity-details
        (refreshList)="refreshList()"
        [identity]="currentIdentity"
      ></app-identity-details>
    </div>

    <div *ngIf="!currentIdentity">
      <br />
      <p>Please click on a Identity...</p>
    </div>
  </div>
</div>
