<div *ngIf="currentTransaction" class="edit-form">
  <h4>Transaction</h4>
  <form>
    <div class="form-group">
      <label for="notes">Notes</label>
      <input
        type="text"
        class="form-control"
        id="notes"
        [(ngModel)]="currentTransaction.notes"
        name="notes"
      />
    </div>
    <div class="form-group">
      <label for="crypto_address">crypto_address</label>
      <input
        type="text"
        class="form-control"
        id="crypto_address"
        [(ngModel)]="currentTransaction.crypto_address"
        name="crypto_address"
      />
    </div>
    <div class="form-group">
      <label for="phone_number">phone_number</label>
      <input
        type="text"
        class="form-control"
        id="phone_number"
        [(ngModel)]="currentTransaction.phone_number"
        name="phone_number"
      />
    </div>
    <div class="form-group">
      <label for="amount_fiat_entered">amount_fiat_entered</label>
      <input
        type="text"
        class="form-control"
        id="amount_fiat_entered"
        [(ngModel)]="currentTransaction.amount_fiat_entered"
        name="amount_fiat_entered"
      />
    </div>
    <!--<div class="form-group">
      <label for="crypto">crypto</label>
      <input
        type="text"
        class="form-control"
        id="crypto"
        [(ngModel)]="currentTransaction.crypto"
        name="crypto"
      />
    </div>
    
    <div class="form-group">
      <label for="crypto_amount">crypto_amount</label>
      <input
        type="text"
        class="form-control"
        id="crypto_amount"
        [(ngModel)]="currentTransaction.crypto_amount"
        name="crypto_amount"
      />
    </div>-->
    <div class="form-group">
      <label for="crypto_amount_in_fiat">crypto_amount_in_fiat</label>
      <input
        type="text"
        class="form-control"
        id="crypto_amount_in_fiat"
        [(ngModel)]="currentTransaction.crypto_amount_in_fiat"
        name="crypto_amount_in_fiat"
      />
    </div>
    <div class="form-group">
      <label for="identity_id"><b>Identity ID</b></label>
      <input
        type="text"
        class="form-control"
        id="identity_id"
        [(ngModel)]="currentTransaction.identity_id"
        name="Identity ID"
      />
    </div>
  
    <!--<div class="form-group">
      <label for="fee_at_purchase">fee_at_purchase</label>
      <input
        type="text"
        class="form-control"
        id="fee_at_purchase"
        [(ngModel)]="currentTransaction.fee_at_purchase"
        name="fee_at_purchase"
      />
    </div>
    <div class="form-group">
      <label for="fiat">fiat</label>
      <input
        type="text"
        class="form-control"
        id="fiat"
        [(ngModel)]="currentTransaction.fiat"
        name="fiat"
      />
    </div>
    <div class="form-group">
      <label for="time">time</label>
      <input
        type="text"
        class="form-control"
        id="time"
        [(ngModel)]="currentTransaction.time"
        name="time"
      />
    </div>
    <div class="form-group">
      <label for="machine_id">machine_id</label>
      <input
        type="text"
        class="form-control"
        id="machine_id"
        [(ngModel)]="currentTransaction.machine_id"
        name="machine_id"
      />
    </div>
    <div class="form-group">
      <label for="identity_id">identity_id</label>
      <input
        type="text"
        class="form-control"
        id="identity_id"
        [(ngModel)]="currentTransaction.identity_id"
        name="identity_id"
      />
    </div>-->

    <!--<div class="form-group">
      <label><strong>Status:</strong></label>
      {{ currentTransaction.published ? "Published" : "Pending" }}
    </div>-->
  </form>

  <!--<button
    class="badge badge-primary mr-2"
    *ngIf="currentTransaction.published"
    (click)="updatePublished(false)"
  >
    UnPublish
  </button>
  <button
    *ngIf="!currentTransaction.published"
    class="badge badge-primary mr-2"
    (click)="updatePublished(true)"
  >
    Publish
  </button>

  <button class="badge badge-danger mr-2" (click)="deleteTransaction()">
    Delete
  </button>-->

  <button type="submit" class="badge badge-success" (click)="updateTransaction()">
    Update
  </button>
  <p>{{ message }}</p>
</div>

<div *ngIf="!currentTransaction">
  <br />
  <p>Cannot access this Transaction...</p>
</div>
