import { Component, OnInit } from '@angular/core';
import { IdentityService } from 'src/app/services/identity.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-identity-list',
  templateUrl: './identity-list.component.html',
  styleUrls: ['./identity-list.component.css']
})
export class IdentityListComponent implements OnInit {

  identitys: any;
  currentIdentity = null;
  currentIndex = -1;
  title = '';

  constructor(private identityService: IdentityService) { }

  ngOnInit(): void {
    this.retrieveIdentitys();
  }

  refreshList(): void {
    this.currentIdentity = null;
    this.currentIndex = -1;
    this.retrieveIdentitys();
  }

  retrieveIdentitys(): void {
    this.identityService.getAll().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      this.identitys = data;
    });
  }

  setActiveIdentity(identity, index): void {
    this.currentIdentity = identity;
    this.currentIndex = index;
  }
}
