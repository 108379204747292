import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IdentityListComponent } from './components/identity-list/identity-list.component';
import { IdentityDetailsComponent } from './components/identity-details/identity-details.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { TransactionDetailsComponent } from './components/transaction-details/transaction-details.component';
import { LoginComponent } from './login/login.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DataTablesModule } from "angular-datatables"; //For revamp and sort/filter from https://l-lin.github.io/angular-datatables -Miguel



@NgModule({
  declarations: [
    AppComponent,
    IdentityListComponent,
    IdentityDetailsComponent,
    TransactionListComponent,
    TransactionDetailsComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // for firestore
    NgxChartsModule,
    DataTablesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
