import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import Organization from '../models/organization';

@Component({
  selector: 'app-login',
  template: `
  			<p>Username</p>
  			<input [(ngModel)]="username" #ctrl="ngModel" required>

			<p>Password</p>
			<input type="password" [(ngModel)]="password" #ctrl="ngModel" required>

			<button (click)="doSubmit()">Submit</button>`,
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private dbPath = '/Organizations';
  organizationsRef: AngularFirestoreCollection<Organization> = null;

  constructor(private _router : Router, private db: AngularFirestore) {
    this.organizationsRef = db.collection(this.dbPath);
  }

  ngOnInit(): void {
  }

  username: string = '';
  password: string = '';

  doSubmit(){
    //for (let o of this.organizationsRef.collection()){
    	//if(this.username === o.get("portaluser") && this.password === o.get("portalpass")){
    		//this._router.navigate(['/transactions']);
    	//}
    //}
    if(this.username === "admin" && this.password === "admin"){
      this._router.navigate(['/transactions']);
    }
  }

}
