import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { IdentityService } from 'src/app/services/identity.service';
import Identity from 'src/app/models/identity';

@Component({
  selector: 'app-identity-details',
  templateUrl: './identity-details.component.html',
  styleUrls: ['./identity-details.component.css']
})
export class IdentityDetailsComponent implements OnInit, OnChanges {

  @Input() identity: Identity;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  currentIdentity: Identity = null;
  message = '';

  constructor(private identityService: IdentityService) { }

  ngOnInit(): void {
    this.message = '';
  }

  ngOnChanges(): void {
    this.message = '';
    this.currentIdentity = { ...this.identity };
  }

  /**
  updatePublished(status): void {
    this.identityService.update(this.currentIdentity.id, { published: status })
      .then(() => {
        this.currentIdentity.published = status;
        this.message = 'The status was updated successfully!';
      })
      .catch(err => console.log(err));
  }**/

  updateIdentity(): void {
    const data = {
      notes: this.currentIdentity.notes,
      first_name: this.currentIdentity.first_name,
      last_name: this.currentIdentity.last_name,
      phone_numbers: this.currentIdentity.phone_numbers,
      btc_addresses: this.currentIdentity.btc_addresses,
      licenses: this.currentIdentity.licenses,
      identity_id: this.currentIdentity.identity_id
    };

    this.identityService.update(this.currentIdentity.id, data)
      .then(() => this.message = 'The identity was updated successfully!')
      .catch(err => console.log(err));
  }

  deleteIdentity(): void {
    this.identityService.delete(this.currentIdentity.id)
      .then(() => {
        this.refreshList.emit();
        this.message = 'The identity was updated successfully!';
      })
      .catch(err => console.log(err));
  }
}