import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import Transaction from '../models/transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private dbPath = '/Transactions';

  transactionsRef: AngularFirestoreCollection<Transaction> = null;

  constructor(private db: AngularFirestore) {
    this.transactionsRef = db.collection(this.dbPath);
  }

  getAll(): AngularFirestoreCollection<Transaction> {
    return this.transactionsRef;
  }
  
  create(transaction: Transaction): any {
    return this.transactionsRef.add({ ...transaction });
  }

  update(id: string, data: any): Promise<void> {
    return this.transactionsRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {
    return this.transactionsRef.doc(id).delete();
  }

  
}
