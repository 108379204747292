<link type="text/css" rel="stylesheet" href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css" />
<nav id="sidebar">
  <div class="sidebar-header">
    <h5>Navigation</h5>
  </div>

  <li class="nav-item active">
    <!--<a routerLink="transactions" class="nav-link">Transactions</a>-->
    <a href="../transactions">Transactions</a>
  </li>
  <li class="nav-item">
    <a href="../identities">Identities</a>
  </li>
</nav>
<div class="row" style="padding-bottom:30px;"></div>

<form (submit)="filterByCol()">
  <label>
    Min
    <input type="number" name="min" id="min" [(ngModel)]="min" />
  </label>
  <br>
  <label>
    Max
    <input type="number" name="max" id="max" [(ngModel)]="max" />
  </label>
  <br>
  <button class="btn btn-primary" type="submit">Filter by Amount</button>
</form>
<br />
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
  <thead>
    <tr>
      <th>Time</th>
      <th>Transaction ID</th>
      <th>Fiat Amount</th>
      <th>Details</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let transaction of transactions; let i = index" (click)="toggleAccordian($event, i)">

      <ng-container class="table-row accordion">
        <td>{{ transaction.time | date:"MM/dd/yy hh:mm:ss" }}</td>
        <td>{{ transaction.id }}</td>
        <td>{{ transaction.amount_fiat_entered | currency:'USD':true}}</td>
      </ng-container>


      <td>
        <div class="details">
          <li>
            <label>Organization ID:</label>
            <div>{{transaction.id.split('-')[0]}}</div>
          </li>
          <li>
            <label>Server ID:</label>
            <div>{{transaction.id.split('-')[1]}}</div>
          </li>
          <li>
            <label>Machine Brand:</label>
            <div *ngIf="transaction.id.split('-')[2] == 1; else other;">General Bytes</div>
            <ng-template #other>Other</ng-template>
          </li>
          <li>
            <label>Server Machine ID:</label>
            <div>{{transaction.id.split('-')[3]}}</div>
          </li>
          <li>
            <label>Local Transaction ID:</label>
            <div>{{transaction.id.split('-')[4]}}</div>
          </li>
          <button type="button" class="collapsible">Expand</button>

          <div class="panel" hide="!item.isActive">
            <li>
              <label>Crypto:</label>
              <div>{{transaction.crypto}}</div>
            </li>
            <li>
              <label>Wallet Address:</label>
              <div>{{transaction.crypto_address}}</div>
            </li>
            <li>
              <label>Amt. Crypto Bought:</label>
              <div>{{transaction.crypto_amount}}</div>
            </li>
            <li>
              <label>Discount:</label>
              <div>{{transaction.cryptodiscountamount}}</div>
            </li>
            <li >
              <label >Detail:</label>&nbsp;
              <div style="background-color: #eee; cursor: pointer; color: #444; border-radius: 10px;"> {{transaction.detail.slice(0,6)}}....</div>
              <div class="panel" hide="!item.isActive">{{transaction.detail}}</div>
            </li>
            <li>
              <label>Discount Code:</label>
              <div>{{transaction.discount_code}}</div>
            </li>
            <li>
              <label>Expected Profit %:</label>
              <div>{{transaction.expectedprofitsetting | number:'1.3'}}%</div>
            </li>
            <li>
              <label>Expected Profit $:</label>
              <div>${{transaction.expectedprofitvalue | number:'1.3'}}</div>
            </li>
            <li>
              <label>Fee Discount:</label>
              <div>{{transaction.feediscount}}</div>
            </li>
            <li>
              <label>Currency Code:</label>
              <div>{{transaction.fiat}}</div>
            </li>
            <li>
              <label>Fixed Transaction Fee:</label> 
              <div>{{transaction.fixedtransactionfee | number:'1.3'}}</div>
            </li>
            <li>
              <label>FG Identity ID:</label>
              <div *ngIf="transaction['internal identity UID'] != null; else other">{{transaction['internal identity UID']}}
              </div> <!-- See issue notes -->
              <ng-template #other>Other</ng-template>
            </li>
            <li>
              <label>Note:</label>
              <div>
                <button class="btn btn-primary" (click)="noteLink(transaction)" >Go to Address</button>
              </div>
            </li>
            <li>
              <label>Phone Number:</label>
              <div>{{transaction.phone_number}}</div>
            </li>
            <li>
              <label>Crypto Price/Coin:</label>
              <div>{{transaction.ratesourceprice | currency:'USD':true}}</div>
            </li>
            <li>
              <label>C. Setting Used:</label>
              <div>{{transaction.nameofcryptosettingused}}</div>
            </li>
            <li>
              <label>Server Time:</label>
              <div>{{transaction.servertime | date:"MM/dd/yy hh:mm:ss"}}</div>
            </li>
            <li>
              <label>Status:</label>
              <div>{{transaction.status}}</div>
            </li>
          </div>
        </div>


      </td>

    </tr>
  </tbody>
</table>
