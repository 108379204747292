<div>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <link rel='shortcut icon' type='image/x-icon' href='/favicon.ico' />
    <a href="#" class="navbar-brand">Compliance Portal - Alpha 1.2</a>
    <link type="text/css" rel="stylesheet" href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css" />
  </nav>

  <div class="container mt-3">
    <h2>{{ title }}</h2>
    <router-outlet></router-outlet>
  </div>
</div>
